import React, {useEffect, useState}  from 'react';
import e3Style from '../../../../css/e3-style.css';
import sprStyle from '../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import Joi from "joi";
import { useValidator } from "react-joi";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../store/bodyView";
import { getSTSCntcts } from "../../../store/STSContactList";
import StatusMsg from "../../util/statusMsg";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";

import {STSMessagesMessagesPath, loginPath} from "../../layout/body";
import FocusWrappedGrp from "../../util/focusWrappedGrp";
import FocusWrappedTextarea from "../../util/focusWrappedTextarea";
import FocusWrappedInput from "../../util/focusWrappedInput";
import FocusWrappedLabel from "../../util/focusWrappedLabel";
import {stripNonNums} from "../../util/stringFormatHelper";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";

const AddSTSMessage = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const curSTSContacts = useSelector(getSTSCntcts);
	const params = useParams();
	const actionType = params.actionType;
	const itemIdx = params.itemIdx;

	const [oldMsgText, setOldMsg] = useState("");

	const chkPhoneNbrInvalid = (value, helpers) => {
		if( phoneNbrInvalid )
			return helpers.error('any.invalid');
		else
			return value;
	};
	
	const schema_phoneNumber = Joi.string().trim().regex(/^(\(\s*\d{3}\s*\)|(\d{3}))\s*(-\s*)?\d{3}\s*(-\s*)?\d{4}$/).custom(chkPhoneNbrInvalid, 'check if phone number is valid NPA/NXX').required().messages({
		'string.empty':'flows.registration.reginfo.27',
		'string.pattern.base': 'flows.registration.reginfo.28',
		'any.invalid':'flows.registration.reginfo.28'
	});
	
	const { state, setData, setExplicitField, validate } = useValidator({
		initialData: {
			msgText: "",
			phoneNumber: "",
			name: ""
		},
 	schema: Joi.object({
			msgText: Joi.string().trim().min(1).max(3900).required().messages({
				'string.empty':'flows.admin.notes.title.4',
				'string.max':'flows.admin.notes.title.5'
			}),
			phoneNumber: Joi.string().required().pattern(/^(?:(?:\(\s*\d{3}\s*\)|(?:\d{3}))\s*(?:-\s*)?\d{3}\s*(?:-\s*)?\d{4})$|^(?:\d{10})$/).messages({
				'string.base': 'phoneNumber.invalidFormat.error',
				'string.empty': 'phoneNumber.required.error',
				'any.required': 'phoneNumber.required.error',
				'string.pattern.base': 'phoneNumber.invalidFormat.error'
			}),
			name: Joi.string().trim().min(2).max(25).required().messages({
				'string.empty':'flows.registration.reginfo.12',
				'string.max':'flows.admin.notes.title.5'
			}),
		}),
		explicitCheck: {
			msgText: false,
			phoneNumber: false,
			name: false,
		}
	});

	useEffect(() => {
		if( actionType === 'editMsg' || actionType === 'renewMsg') getMessage();
		else if( actionType === 'addMsgCntct' ) getContact();
	}, []);

	const getContact = async () => {
		if (curSTSContacts?.length > 0){
			const matchedIdx = curSTSContacts.findIndex(
					value => value.PHONE_NUMBER === itemIdx );
			if(matchedIdx >= 0)
				setData((old) => ({
					...old,
					name: curSTSContacts[matchedIdx].NAME,
					phoneNumber: curSTSContacts[matchedIdx].PHONE_NUMBER
				})
				);
		}
	}
	
	const getMessage = async () => {
		try {
			let theMsgText = "";
			let theName = "";
			let thePhoneNbr = "";
			if( oldMsgText.length === 0) {
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{command: {name: 'getSTSMessages', csrfSessionNonce}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							const msgText = String(Object.values(data.response.STSMessages)[itemIdx].TEXT);
							if( msgText.startsWith("Call ") && msgText.includes(" at ") && msgText.includes(": ") ) {
								theName = msgText.substring(5, msgText.indexOf(" at "));
								thePhoneNbr = msgText.substring( msgText.indexOf(" at ")+4,msgText.indexOf(": "));
								theMsgText = msgText.substring(msgText.indexOf(": ")+2);
							}							
							setData((old) => ({
									...old,
								msgText: theMsgText,
								name: theName,
								phoneNumber: thePhoneNbr
							}));
							setOldMsg(msgText);
						  	break;

						case 998:
							setHistoryPath(dispatch, navigate, loginPath);
							break;

						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} 
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const saveMessage = async () => {
		try {
			let cmdName = 'adminAddSTSMsg';
			let msgData = {newMsg:'Call '+state.$data.name.trim()+' at '+stripNonNums(state.$data.phoneNumber)+': '+state.$data.msgText.trim()};
			if( actionType === 'editMsg' ||  actionType === 'renewMsg') {
				cmdName = 'adminUpdateSTSMsg';
				msgData = {newMsg:'Call '+state.$data.name.trim()+' at '+stripNonNums(state.$data.phoneNumber)+': '+state.$data.msgText.trim(), 
						   oldMsg:oldMsgText};
			}
			
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: cmdName, csrfSessionNonce}, params: msgData}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setHistoryPath(dispatch, navigate, STSMessagesMessagesPath);
					    break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const updateField = (e, name) => {
		setData((old) => ({
			...old,
			[name]: e.target.value
		}));
	};

	const submitMsg = e => {
		e.preventDefault();
		saveMessage();
	};

	const onCancel = e => {
		e.preventDefault();
		setHistoryPath(dispatch, navigate, STSMessagesMessagesPath);
	}
	
	const formatPhoneNbr = (value) => {
	  	if (!value) return value;
	  	const currentValue = stripNonNums(value);
	  	const cvLength = currentValue.length;
	    if (cvLength < 4) return currentValue;
	    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
	   	if (cvLength < 11)
	    	return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
		else
			return currentValue;
	  	
	};
	
	return(
		<>
			<StatusMsg/>
			{ (actionType !== 'editMsg' || actionType === 'renewMsg' || oldMsgText.length > 0) &&
			<form noValidate autoComplete="off" onSubmit={submitMsg} className={e3Style['account-preferences_notes']}>
				<h2>{t((actionType === 'editMsg'|| actionType === 'renewMsg')? 'flows.admin.stsmessage.title.2':'flows.admin.stsmessage.title.1')}</h2>
				{actionType !== 'editMsg' &&
				<h4 className={`${e3Style['sprint-alert--error']}`}>{t('flows.admin.stsmessage.title.3')}</h4>
				}
				<fieldset class={`${e3Style['sprint-notes']} ${e3Style['sprint-account_form_items']}`}>
					<div className={e3Style.row}>
				      	<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
					        <FocusWrappedGrp baseAriaId="nameErr" cptErrCount={state.$errors.name.length}>
						        <FocusWrappedLabel className={e3Style['text-uppercase']}
						               htmlFor="name">*{t('page.popup.name') + ' '}</FocusWrappedLabel>
						        <FocusWrappedInput type="text" autoFocus id="name" name="name"
						               onChange={e => updateField(e, 'name')}
						               onBlur={() => setExplicitField('name', true)}
						        	   value={state.$data.name}
						               className={e3Style['input--fluid']}/>
						        {!!state.$errors.name.length &&
							        <div className={e3Style['sprint-alert--error']}>
								        <div className={e3Style['alert--error_message']}>
									        {state.$errors.name.map((err, idx) => <p index={idx} id={'nameErr-' + idx}><span>{t(err.$message)}</span></p>)}
								        </div>
							        </div>
						        }
					        </FocusWrappedGrp>
						</div>
					</div>
					<div className={e3Style.row}>
				      	<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
					        <FocusWrappedGrp baseAriaId="phNumErr" cptErrCount={state.$errors.phoneNumber.length}>
						        <FocusWrappedLabel className={e3Style['text-uppercase']}
						               htmlFor="phoneNumber">*{t('page.popup.phnum') + ' '}</FocusWrappedLabel>
						        <FocusWrappedInput type="tel" name="phoneNumber" id="phoneNumber"
						               onChange={e =>{e.target.value = formatPhoneNbr(e.target.value);updateField(e, 'phoneNumber')}}
						               onBlur={() => setExplicitField('phoneNumber', true)}
						        	   value={state.$data.phoneNumber}
						               className={e3Style['input--fluid']} placeholder="(000) 123-4567"/>
						        {!!state.$errors.phoneNumber.length &&
							        <div className={e3Style['sprint-alert--error']}>
								        <div className={e3Style['alert--error_message']}>
									        {state.$errors.phoneNumber.map((err, idx) => <p index={idx} id={'phNumErr-' + idx}><span>{t(err.$message)}</span></p>)}
								        </div>
							        </div>
						        }
					        </FocusWrappedGrp>
					   </div>
				   </div>
				</fieldset>
				<FocusWrappedGrp baseAriaId="msgTextErr" cptErrCount={state.$errors.msgText.length}>
					<FocusWrappedLabel className={e3Style['text-uppercase']}
						htmlFor="msgText">*{t('page.call.6') + ' '}</FocusWrappedLabel>
					<FocusWrappedTextarea initialFocus name="msgText" id="msgText" className={sprStyle['add-agt-note-ta']}
					                      onChange={e => updateField(e, 'msgText')}
					                      onBlur={() => setExplicitField('msgText', true)}
					                      value={state.$data.msgText}/>
					{!!state.$errors.msgText.length &&
						<div className={e3Style['sprint-alert--error']}>
							<div className={e3Style['alert--error_message']}>
								{state.$errors.msgText.map((err, idx) => <p index={idx} id={'msgTextErr-' + idx}><span>{t(err.$message)}</span></p>)}
							</div>
						</div>
					}
				</FocusWrappedGrp>
				<div className={e3Style.row}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
					<button type="button" onClick={onCancel}
						className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-md']} ${e3Style['button--md']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
					{t('button.cancel')} </button>

					<button type="submit" onClick={validate} disabled={state.$auto_invalid}
			            className={`${e3Style.button} ${e3Style['button--minwidth-md']} ${e3Style['button--md']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
					{t('button.submit')} </button>
					</div>
				</div>
			</form>
			}
		</>
	);
};

export default AddSTSMessage;
