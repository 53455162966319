import React, {useEffect, useState} from "react";
import e3Style from '../../../../css/e3-style.css';
import sStyle from '../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import FocusWrappedGrp from "../../util/focusWrappedGrp";
import FocusWrappedInput from "../../util/focusWrappedInput";
import FocusWrappedLabel from "../../util/focusWrappedLabel";
import {stripNonNums} from "../../util/stringFormatHelper";


const STSMsgItem = ({
  theMsgItem,
  onDeleteFunc,
  onUpdateFunc,
  expired=false
}) => {
	const { t } = useTranslation();

	const [ msgItem, setMsgItem] = useState({...theMsgItem});
	const [ showDelete, setShowDelete ] = useState(true);
	const [ showDelConfirm, setShowDelConfirm ] = useState(false);

	useEffect(() => {
		if( msgItem.ORDERID !== msgItem.ORDERID || msgItem.TEXT !== msgItem.TEXT ||
			msgItem.EXPDATE !== msgItem.EXPDATE )
			setMsgItem(theMsgItem);
	}, [theMsgItem, msgItem]);

	const formatPhoneNbr = (value) => {
	  	if (!value) return value;
	  	const currentValue = stripNonNums(value);
	  	const cvLength = currentValue.length;
	    if (cvLength < 4) return currentValue;
	    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
	   	if (cvLength < 11)
	    	return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
		else
			return currentValue;
	  	
	};
	
	const isFormatted = (msgText) => {
		return msgText.startsWith("Call ") && msgText.includes(" at ") && msgText.includes(": "); 
	}
	
	const getName = (msgText) => {
		return	msgText.substring(5, msgText.indexOf(" at "));							
	}
	
	const getPhoneNbr = (msgText) => {
		return msgText.substring( msgText.indexOf(" at ")+4,msgText.indexOf(": "));							
	}
	
	const getTextOnly = (msgText) => {
		return msgText.substring(msgText.indexOf(": ")+2);						
	}
	
	return (
			<>
				<hr/>
				<div className={`${e3Style.row} ${e3Style['mt-10']}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-10']} ${e3Style['display-flex']}`}> 
					{ expired? 
						<label className={`${e3Style['input--fluid']} ${e3Style['focused-label']}`} id="expiration">{t('flows.admin.stsmessage.title.5')} {msgItem.EXPDATE}</label>
						:
						<label className={`${e3Style['input--fluid']}`} id="expiration">{t('flows.admin.stsmessage.title.4')} {msgItem.EXPDATE}</label>
					}	
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-2']} ${e3Style['display-flex']}`}>
						<a href='#' className={`${e3Style['button--link']} ${e3Style['sprint-remove_item']}`} onClick={e => {e.preventDefault();onUpdateFunc(msgItem.ORDERID, expired)}}>
						{t(expired? 'button.renew':'button.edit')}</a>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-4']} ${e3Style['display-flex']}`}>
					{ showDelete &&
						<a href='#' className={`${e3Style['button--link']} ${e3Style['sprint-remove_item']}`} onClick={e => {e.preventDefault();setShowDelete(false);setShowDelConfirm(true)}}>{t('button.delete')}</a>
					}
					{ showDelConfirm &&
						<>
						<label className={`${sStyle['confirm-delete']}`}>{t('embedded.12')}</label>
						<a href='#' className={`${e3Style['button--link']} ${e3Style['sprint-remove_item']}`} onClick={e => {e.preventDefault();onDeleteFunc(msgItem.ORDERID)}}>{t('button.yes')}</a>
						<a href='#' className={`${e3Style['button--link']} ${e3Style['sprint-remove_item']}`} onClick={e => {e.preventDefault();setShowDelConfirm(false);setShowDelete(true)}}>{t('button.cancel')}</a>
						</>
					}
					</div>
				</div>
				<div className={`${e3Style.row} ${e3Style['mt-0']}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-24']} ${e3Style['mb-0']} ${e3Style['display-flex']}`}>					
						{!isFormatted(msgItem.TEXT)? 
							<label className={expired? `${e3Style['input--fluid']} ${e3Style['focused-label']}` : `${e3Style['input--fluid']}`} id="text">
							{msgItem.TEXT}
							</label> 
							:
							<label className={expired? `${e3Style['input--fluid']} ${e3Style['focused-label']}` : `${e3Style['input--fluid']}`} id="text">
							{t('button.call')} <b>{getName(msgItem.TEXT)}</b>{t('flows.admin.stsmessage.title.6')}<b>{formatPhoneNbr(getPhoneNbr(msgItem.TEXT))}</b><br/>{getTextOnly(msgItem.TEXT)}
							</label> 
						}
					</div>
				</div>
			</>
	);
};

export default STSMsgItem;